<template>


    <div :class="{item: true, is_new: notification.is_new}">

      <div class="item_table" v-if="notification.type == 'add_blog'">

        <div class="blog_one">
         <div v-if="notification.blog_preview_image"><img :alt="notification.blog_title" :src="notification.blog_preview_image"></div>
        </div>
        <div class="blog_two">
         <h3>{{ notification.blog_title }}</h3>
         <div v-html="notification.blog_preview + '...'"></div>
         <div>
           <NuxtLink :to="notification.link">
              <div class="btn">{{ $t('notes_view_read_more') }}</div>
           </NuxtLink>
         </div>
        </div>

      </div>

      <div class="item_table" v-else-if="notification.type == 'add_message'">

        <div class="message_content" v-html="notification.message_text"></div>

      </div>

      <div class="item_table" v-else>
        <div class="col_one"> 
          <NuxtLink class="user_img" :to="notification.items_user[0].link" v-if="notification.items_user?.length">
             <img :alt="notification.items_user[0].name" :src="notification.items_user[0].avatar_small">
             <div :class="'user_range u_range_' + notification.items_user[0].status"></div>
          </NuxtLink>
          <div class="user_info more_users_link">
            
            <NuxtLink 
                :to="notification.items_user[0].link" 
                v-if="notification.items_user && notification.items_user.length">
                {{ notification.items_user[0].name }}
            </NuxtLink>

            <span>
              <template v-if="notification.items_user.length > 1">
                <span @click="activeMoreUsers = true" class="more_users_word">+{{ notification.items_user.length-1 }} others</span>
              </template> 
              {{ notification.action ? $t(notification.action) : '' }}
            </span>


            <NotificationMoreUsers
              v-if="notification.items_user.length > 1"
              :list="notification.items_user.slice(1,9)"
              :exists-more="notification.items_user.length >= 10"
              :active="activeMoreUsers"
              :notification-id="notification.id"
              @close="activeMoreUsers = false"
              />



            <span class="dt">{{ $dayjs.utc(notification.last_update).fromNow() }} </span>
 

          </div>

        </div>
        <div class="col_two">
          <i class="icon_hr"></i>
          <i v-if="notification.type == 'like_post'" class="icon-leaf"></i>
          <i v-if="notification.type == 'like_blog_comment'" class="icon-leaf"></i>
          <i v-if="notification.type == 'like_comment'" class="icon-leaf"></i>
          <i v-if="notification.type == 'follow_user'" class="icon-user-new"></i>
          <i v-if="notification.type == 'follow_report'" class="icon-user-new"></i>
          <i v-if="notification.type == 'add_diary_official'" class="icon-user-new"></i>
          <i v-if="notification.type == 'comment_post'" class="icon-comment"></i>
          <i v-if="notification.type == 'reply_comment'" class="icon-comment-outline"></i>
          <i v-if="notification.type == 'share_post'" class="icon-share"></i>
          <i v-if="notification.type == 'add_vote'" class="icon-trophy"></i>
          <i v-if="notification.type == 'up_status'" class="icon-circle-up"></i>
          <i v-if="notification.type == 'add_blog'" class="icon-plus-round"></i>
          <i v-if="notification.type == 'add_message'" class="icon-comment"></i>
          <i class="icon_hr"></i>
        </div>
        <div class="col_three">

          <NuxtLink class="" :to="notification.link" v-if="notification.type == 'like_comment' || notification.type == 'comment_post' || notification.type == 'reply_comment'">
            <div class="comment_box" v-html="notification.comment_text"></div>
          </NuxtLink>

          <NuxtLink class="" :to="'/diaries/' + notification.report_link + (notification.type == 'share_post' || notification.type == 'like_post' ? '#week/' + notification.post_id : '')" v-else-if="notification.type == 'follow_report' || notification.type == 'add_diary_official' || notification.type == 'add_vote' || notification.type == 'share_post' || notification.type == 'like_post'">
            <div class="report_box">
              <div class="report_avatar">
                <img :alt="notification.report_name" :src="notification.report_avatar">

                <i v-if="notification.report_awards && notification.report_awards['name']" data-popup data-content="notification.report_awards['name']" :class="notification.report_awards['icon'] + ' icon score_diary'"></i> 
                <i v-else data-popup :data-content="$t($constants.growScoreText[notification.report_progress_str])"  :class="notification.report_progress_str + ' icon score_diary'"></i>

              </div>
              <div class="report_name">
                {{ notification.report_name }}
                <div :class="'item_faza faza_' + notification.post_faza" v-if="notification.type == 'share_post' || notification.type == 'like_post'">
                  <div class="faza_name">{{ $t($constants.typeFazaShort[notification.post_faza]) }}</div>
                  {{ notification.post_num }}
                </div>
              </div>
            </div>
          </NuxtLink>
          <NuxtLink class="" :to="'/grower/' + notification.following_name" v-else-if="notification.type == 'follow_user'">
            <div class="user_box">
              <div class="user_img">
                <img :alt="notification.following_name" :src="notification.following_avatar">
                 <div :class="'user_range u_range_' + notification.following_status"></div>
              </div>
              <div class="user_info">
                <div class="user_name">
                  {{ notification.following_name }}
                </div>
              </div>
            </div>
          </NuxtLink>
          <NuxtLink class="" :to="notification.link" v-else-if="notification.type == 'like_blog_comment' || notification.type == 'comment_blog_reply'">
            <div class="comment_box" v-html="notification.comment_text"></div>
          </NuxtLink>
          <div class="up_status" v-else-if="notification.type == 'up_status'">            
            <div  class="box_status new">
              <div :class="'user_range u_range_' + notification.items_user[0].status"></div>
              <span>{{ $t($constants.status[notification.items_user[0].status]) }}</span>
            </div>

          </div>

        </div>
      </div>

    </div>

</template>




<script setup>
 
 const activeMoreUsers = ref(false)

const props = defineProps({
  notification: {
    type: Object,
    required: false
  }
});



</script>



<style scoped>
 
  
.item {
    padding: 0.8rem 2rem;
    margin-bottom: 5px;
    /* border-radius: 3px; */
    border-bottom: 1px var(--un-element-color-gray) solid;
}

.item.is_new {
  background-color: rgba(119, 175, 74, 0.12);
  padding: 1rem;
}
.item .is_new {
    background: rgb(119, 175, 74);
    content: "";
    position: absolute;
    display: block;
    left: -1.2rem;
    width: 10px;
    height: 10px;
    /* margin-top: -61px; */
    z-index: 100;
    top: calc(50% - 6px);
    border-radius: 30px;
}

.item_table {
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.item .col_one {
    
    display: flex;
    align-items: center;
    width: 40%;
    vertical-align: middle;
    position: relative;
}

.item .col_two {
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    text-align: center;
    color: var(--un-element-color-gray);
    vertical-align: middle;
}

.item .col_two i{
    vertical-align: middle;
    font-size: 1.4rem;
    margin-left: 5px;
    margin-right: 5px;
    line-height: 1.3rem;
}

.item .col_two .icon_hr{
    display: inline-block;
    width: 20px;
    height:1px;
    border-bottom: 1px var(--un-element-color-gray) solid;
}

.item.is_new .col_two{
    color: #68ae3c;
}

.item.is_new .col_two .icon_hr{
    border-bottom: 1px #68ae3c solid;
}
.item .col_three {
    display: flex;
    align-content: center;
    width: 40%;
    vertical-align: middle;

}

.item .col_one .title {
    margin-bottom: 7px;
}

.item .col_one .more_users_btn {
   /* float: right; */
}

/* USER */

.item .user_img {
    width: 40px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    flex-shrink: 0;
    flex-shrink:0;
}

.item .user_img img {
    width: 100%;
    border-radius: 200px;
}

.item .user_img .user_range {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 1.5rem;
    margin-right: 0px;
}

.item .user_name {
display: inline-block;
color: #4183c4;
}

.item .user_info {
    display: block;
    width: 100%;
}
.item .user_info a {
    margin-right: 5px;
}
.dt{
    color: var(--un-text-color-gray);
    font-size: 0.8rem;
    margin-left: 5px;
}
.item .user_action {
    display: inline-block;
    color: var(--un-text-color);
}
.item .comment_box {
    color: var(--un-text-color);
}


/* MORE USERS */
.more_users_word{
  font-weight: bold;
  cursor: pointer;
}

.more_users_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
    padding: 1rem;
}
.more_users {
    display: inline;
    width: 70px;
    min-height: 70px;
    text-align: center;
    overflow: hidden;
}
.more_users .icon.users{
    font-size: 2rem;
    color: gray;
    margin-top: 1.7rem;
}
.more_users .name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden; /* ???????? ???, ??? ?? ?????????? ? ??????? *//* background: #fc0; */
    /* ???? ???? *//* padding: 5px; */
    /* ???? ?????? ?????? */
    text-overflow: ellipsis; /* ????????? ?????????? */
}

.more_users .avatar {
    width: 80%;
    margin: 0px;
    display: inline-block;
}

.more_users .avatar img {
    width: 100%;
    border-radius: 100px;
}

/* REPORT BOX */


.report_box{

    display: flex;
        align-items: center;
        justify-content: flex-start;
}

.report_box .report_avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    border-radius: 3px;
    margin-right: 15px;
    position: relative;
    object-fit: cover;
}

.report_box .report_avatar img {
    width: 100%;
    border-radius: 3px;
}

.report_box .report_name {
  
    display: flex;
        align-items: center;
        justify-content: flex-start;
}


.report_box .score_diary{
    position: absolute;
    display: inline-block;
    background-color: #68ae3c;
    right: 2px;
    top: 4px;
    border-radius: 43px;
    color: white;
    font-size: 1rem;
    height: 15px;
    text-align: center;
    width: 15px;
    line-height: 1rem;
}
.report_box .score_diary.is-awards{
    background-color: rgba(255, 255, 255, 0.35);
    height: 25px!important;
    width: 25px!important;
}


.item .item_faza .faza_name{
    font-size: 0.5rem;
    color: var(--un-text-color-invert);
}
.item .item_faza{
    margin-left: 5px;
    margin-right: 5px;
    color: var(--un-text-color);
    display: inline-block;
    min-width: 23px;
    text-align: center;
    border-radius: 3px;
    font-size: 0.8rem;
    margin-top: 0px;
    vertical-align: middle;
}
.item .item_faza.faza_-1 .faza_name{
    background-color: var(--gd-faza-ger);
}
.item .item_faza.faza_0 .faza_name{
    background-color: var(--gd-faza-veg);
}
.item .item_faza.faza_1 .faza_name{
    background-color: var(--gd-faza-flo);
}
.item .item_faza.faza_2 .faza_name{
    background-color: var(--gd-faza-har);
}
.item .item_faza.faza_-1{
    border: 1px var(--gd-faza-ger) solid;
    border-top: 1px var(--gd-faza-ger) solid;
}
.item .item_faza.faza_0{
    border: 1px var(--gd-faza-veg) solid;
    border-top: 1px var(--gd-faza-veg) solid;
}
.item .item_faza.faza_1{

    border: 1px var(--gd-faza-flo) solid;
    border-top: 1px var(--gd-faza-flo) solid;
}
.item .item_faza.faza_2{

    border: 1px var(--gd-faza-har) solid;
    border-top: 1px var(--gd-faza-har) solid;
}


/* USER CONTENT */


.user_box .user_img {
    width: 50px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}
/* .user_box .user_img::after {
} */

.user_box .user_img img {
    width: 100%;
    border-radius: 200px;
}

.user_box .user_img .user_range {
    position: absolute;
    bottom: 0px;
    right: -3px;
    font-size: 1.5rem;
    margin-right: 0px;
}

.user_box .user_name {
    display: inline-block;
    color: #4183c4;
}

.user_box .user_info {
    display: inline-block;
    width: calc(100% - 70px);
}


/**/


/* ADD BLOG */

.item .blog_one{
    display: table-cell;
    /* width: 50%; */
    /* vertical-align: middle; */
    width: 47%;
    vertical-align: middle;
    padding: 1rem 3% 1rem 0rem;
}
.item  .blog_one img{
    width: 100%;
    border-radius: 3px;
}
.item  .blog_two{
    display: table-cell;
    /* width: 50%; */
    /* vertical-align: middle; */
    width: 47%;
    vertical-align: middle;
    padding: 1rem 0px 1rem 3%;
}
.item  .blog_two .p{

}
.item .blog_two .h3{

}


/* UP STATUS */
.item .up_status {

}
.item .up_status .user_range{
        font-size: 2.5rem;
    vertical-align: middle;
    margin-top: -9px;
    margin-right: 10px;
}
.item .up_status .box_status span{

    margin-top: 7px;
    display: inline-block;
}
.item .up_status .box_status {
    display: flex;
    align-items: center;
}

.item .up_status .box_status.old {

}

.item .up_status .box_status.new {

}

.item .up_status .icons {
    display: inline-block;
    margin: 0rem 1rem;
    color: #eeb948;
    font-size: 1.5em;
    vertical-align: middle;
}


/*  MESSAGES */

.item .message_content{
    text-align: center;
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.item .message_content img{
    max-width: 100%;
}











@container pb (max-width: 600px) {

    .item_table{
        display: block;
    }

    .item{
    padding: 1rem 0;
    }

    .item .col_one,
    .item .col_two,
    .item .col_three{
        display: flex;
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }


    .item .up_status .box_status span{
        display: block;
    }

    .item .up_status .box_status{
        text-align: center;
    }
    .item .up_status{
        text-align: center;
    }

    .item .up_status .user_range{
        float: none;
        margin: 0px;
    }

    .report_box{

    }

    .report_box .report_name{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .item .user_info{
    }

    .item .blog_two,
    .item .blog_one{
        display: block;
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

    }
}


</style>
